import React from 'react';
import { graphql, Link } from 'gatsby';
import { Box } from 'grommet';
import {
  TextSection,
  ButtonBanner,
  ResponsiveContext,
  ServicesL3Hero,
  GenericComponent,
  TitleDescription,
} from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

export const query = graphql`
  {
    prismic {
      content: allPower360s {
        edges {
          node {
            intro_title
            intro_copy
            intro_image
            features_title
            features_copy
            possibilities_title
            possibilities_copy
            about_power360
            features {
              title
              text
            }
            possibilities {
              title
              text
              image
            }
            hubspot_contact_us_id
            meta_title
            meta_description
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.content.edges[0].node;
  const features = content.features.map((item) => {
    return {
      name: item.title,
      text: item.text,
    };
  });
  const possibilities = content.possibilities.map((item) => {
    return {
      title: item.title,
      description: item.text,
      image: item.image,
    };
  });

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={content.intro_image && content.intro_image.url}
    >
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <Box>
              <ServicesL3Hero
                headerText={content.intro_title}
                bodyText={content.intro_copy}
                image={content.intro_image}
                htmlSerializer={htmlSerializer}
              />
              <TextSection
                heading={content.features_title}
                body={content.features_copy}
                textSectionCards={features}
                htmlSerializer={htmlSerializer}
              />
              <TitleDescription
                heading={content.possibilities_title}
                description={content.possibilities_copy}
                htmlSerializer={htmlSerializer}
              />
              <GenericComponent
                size={size}
                cardInformation={possibilities}
                htmlSerializer={htmlSerializer}
              />
              <ButtonBanner
                Link={Link}
                heading="Need more information about POWER360®?"
                button="Contact Us"
                href="/contact"
                hubspotFormId={content.hubspot_contact_us_id}
                aboutPower={content.about_power360}
                htmlSerializer={htmlSerializer}
              />
            </Box>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
